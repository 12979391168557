import type { FoodItem, MealOption } from '../types';

export const FOOD_ITEMS: Record<string, FoodItem> = {
  // Proteins
  chicken_breast: {
    name: 'Pechuga de Pollo',
    portionName: 'porción',
    gramsPerPortion: 100,
    proteins: 31,
    carbohydrates: 0,
    fats: 3.6,
    calories: 165,
  },
  eggs: {
    name: 'Huevos',
    portionName: 'unidad',
    gramsPerPortion: 50,
    proteins: 6.3,
    carbohydrates: 0.6,
    fats: 5.3,
    calories: 78,
  },
  tuna: {
    name: 'Atún en Lata',
    portionName: 'lata',
    gramsPerPortion: 100,
    proteins: 26,
    carbohydrates: 0,
    fats: 1,
    calories: 116,
  },
  salmon: {
    name: 'Salmón',
    portionName: 'porción',
    gramsPerPortion: 100,
    proteins: 20,
    carbohydrates: 0,
    fats: 13,
    calories: 208,
  },
  greek_yogurt: {
    name: 'Yogur Griego',
    portionName: 'taza',
    gramsPerPortion: 170,
    proteins: 17,
    carbohydrates: 6,
    fats: 0.4,
    calories: 100,
  },
  cottage_cheese: {
    name: 'Queso Cottage',
    portionName: 'taza',
    gramsPerPortion: 226,
    proteins: 28,
    carbohydrates: 6,
    fats: 2.3,
    calories: 163,
  },
  turkey_breast: {
    name: 'Pechuga de Pavo',
    portionName: 'porción',
    gramsPerPortion: 100,
    proteins: 29,
    carbohydrates: 0,
    fats: 1,
    calories: 135,
  },

  // Carbohydrates
  rice: {
    name: 'Arroz Blanco',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 2.7,
    carbohydrates: 28,
    fats: 0.3,
    calories: 130,
  },
  quinoa: {
    name: 'Quinoa',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 4.4,
    carbohydrates: 21.3,
    fats: 1.9,
    calories: 120,
  },
  sweet_potato: {
    name: 'Boniato',
    portionName: 'unidad mediana',
    gramsPerPortion: 100,
    proteins: 1.6,
    carbohydrates: 20.1,
    fats: 0.2,
    calories: 86,
  },
  oats: {
    name: 'Avena',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 10,
    carbohydrates: 66,
    fats: 6.9,
    calories: 389,
  },
  brown_rice: {
    name: 'Arroz Integral',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 2.6,
    carbohydrates: 23,
    fats: 0.9,
    calories: 111,
  },
  whole_wheat_bread: {
    name: 'Pan Integral',
    portionName: 'rebanada',
    gramsPerPortion: 30,
    proteins: 3.6,
    carbohydrates: 12,
    fats: 1.1,
    calories: 74,
  },
  banana: {
    name: 'Plátano',
    portionName: 'unidad mediana',
    gramsPerPortion: 118,
    proteins: 1.3,
    carbohydrates: 27,
    fats: 0.4,
    calories: 105,
  },

  // Vegetables and Fruits
  spinach: {
    name: 'Espinacas',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 2.9,
    carbohydrates: 3.6,
    fats: 0.4,
    calories: 23,
  },
  broccoli: {
    name: 'Brócoli',
    portionName: 'taza',
    gramsPerPortion: 100,
    proteins: 2.8,
    carbohydrates: 7,
    fats: 0.4,
    calories: 34,
  },
  mixed_berries: {
    name: 'Frutos Rojos',
    portionName: 'taza',
    gramsPerPortion: 150,
    proteins: 1.5,
    carbohydrates: 17,
    fats: 0.5,
    calories: 85,
  },
  apple: {
    name: 'Manzana',
    portionName: 'unidad mediana',
    gramsPerPortion: 182,
    proteins: 0.5,
    carbohydrates: 25,
    fats: 0.3,
    calories: 95,
  },

  // Healthy Fats
  avocado: {
    name: 'Aguacate',
    portionName: 'unidad',
    gramsPerPortion: 100,
    proteins: 2,
    carbohydrates: 8.5,
    fats: 14.7,
    calories: 160,
  },
  olive_oil: {
    name: 'Aceite de Oliva',
    portionName: 'cucharada',
    gramsPerPortion: 15,
    proteins: 0,
    carbohydrates: 0,
    fats: 14,
    calories: 120,
  },
  almonds: {
    name: 'Almendras',
    portionName: 'puñado',
    gramsPerPortion: 30,
    proteins: 6,
    carbohydrates: 6,
    fats: 14,
    calories: 164,
  },
  chia_seeds: {
    name: 'Semillas de Chía',
    portionName: 'cucharada',
    gramsPerPortion: 15,
    proteins: 2.5,
    carbohydrates: 5.5,
    fats: 4.3,
    calories: 68,
  },
};

export const MEAL_OPTIONS: Record<string, MealOption[]> = {
  desayuno: [
    {
      id: 'breakfast_1',
      name: 'Desayuno Energético',
      description: 'Desayuno equilibrado con avena y frutas',
      foods: [
        { foodKey: 'oats', portionMultiplier: 0.7 },
        { foodKey: 'greek_yogurt', portionMultiplier: 1 },
        { foodKey: 'mixed_berries', portionMultiplier: 1 },
        { foodKey: 'chia_seeds', portionMultiplier: 1 },
        { foodKey: 'almonds', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'breakfast_2',
      name: 'Desayuno Proteico',
      description: 'Alto en proteínas para mantener la saciedad',
      foods: [
        { foodKey: 'eggs', portionMultiplier: 3 },
        { foodKey: 'whole_wheat_bread', portionMultiplier: 2 },
        { foodKey: 'spinach', portionMultiplier: 1 },
        { foodKey: 'avocado', portionMultiplier: 0.25 },
      ],
    },
    {
      id: 'breakfast_3',
      name: 'Desayuno Power',
      description: 'Ideal para días de entrenamiento',
      foods: [
        { foodKey: 'cottage_cheese', portionMultiplier: 1 },
        { foodKey: 'banana', portionMultiplier: 1 },
        { foodKey: 'oats', portionMultiplier: 0.5 },
        { foodKey: 'almonds', portionMultiplier: 0.5 },
      ],
    },
  ],
  almuerzo: [
    {
      id: 'lunch_1',
      name: 'Bowl Proteico',
      description: 'Bowl nutritivo con quinoa y pollo',
      foods: [
        { foodKey: 'chicken_breast', portionMultiplier: 1 },
        { foodKey: 'quinoa', portionMultiplier: 1 },
        { foodKey: 'broccoli', portionMultiplier: 1 },
        { foodKey: 'avocado', portionMultiplier: 0.5 },
        { foodKey: 'olive_oil', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'lunch_2',
      name: 'Ensalada Completa',
      description: 'Ensalada nutritiva con atún',
      foods: [
        { foodKey: 'tuna', portionMultiplier: 1 },
        { foodKey: 'brown_rice', portionMultiplier: 0.7 },
        { foodKey: 'spinach', portionMultiplier: 2 },
        { foodKey: 'avocado', portionMultiplier: 0.5 },
        { foodKey: 'olive_oil', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'lunch_3',
      name: 'Bowl Energético',
      description: 'Bowl con pavo y boniato',
      foods: [
        { foodKey: 'turkey_breast', portionMultiplier: 1 },
        { foodKey: 'sweet_potato', portionMultiplier: 1 },
        { foodKey: 'broccoli', portionMultiplier: 1.5 },
        { foodKey: 'almonds', portionMultiplier: 0.5 },
        { foodKey: 'olive_oil', portionMultiplier: 0.5 },
      ],
    },
  ],
  comida: [
    {
      id: 'dinner_1',
      name: 'Salmón Completo',
      description: 'Plato principal con salmón y arroz integral',
      foods: [
        { foodKey: 'salmon', portionMultiplier: 1 },
        { foodKey: 'brown_rice', portionMultiplier: 1 },
        { foodKey: 'broccoli', portionMultiplier: 1.5 },
        { foodKey: 'olive_oil', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'dinner_2',
      name: 'Pollo Power',
      description: 'Plato equilibrado con pollo y quinoa',
      foods: [
        { foodKey: 'chicken_breast', portionMultiplier: 1.2 },
        { foodKey: 'quinoa', portionMultiplier: 1 },
        { foodKey: 'spinach', portionMultiplier: 1.5 },
        { foodKey: 'avocado', portionMultiplier: 0.5 },
        { foodKey: 'olive_oil', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'dinner_3',
      name: 'Bowl Mediterráneo',
      description: 'Bowl saludable con pavo',
      foods: [
        { foodKey: 'turkey_breast', portionMultiplier: 1 },
        { foodKey: 'brown_rice', portionMultiplier: 1 },
        { foodKey: 'broccoli', portionMultiplier: 1 },
        { foodKey: 'olive_oil', portionMultiplier: 0.7 },
        { foodKey: 'almonds', portionMultiplier: 0.5 },
      ],
    },
  ],
  cena: [
    {
      id: 'supper_1',
      name: 'Cena Ligera',
      description: 'Cena equilibrada con proteína y verduras',
      foods: [
        { foodKey: 'greek_yogurt', portionMultiplier: 1 },
        { foodKey: 'mixed_berries', portionMultiplier: 1 },
        { foodKey: 'chia_seeds', portionMultiplier: 1 },
        { foodKey: 'almonds', portionMultiplier: 0.5 },
      ],
    },
    {
      id: 'supper_2',
      name: 'Cena Proteica',
      description: 'Cena rica en proteínas',
      foods: [
        { foodKey: 'eggs', portionMultiplier: 2 },
        { foodKey: 'cottage_cheese', portionMultiplier: 0.5 },
        { foodKey: 'spinach', portionMultiplier: 1 },
        { foodKey: 'avocado', portionMultiplier: 0.25 },
      ],
    },
    {
      id: 'supper_3',
      name: 'Cena Saludable',
      description: 'Cena ligera con atún',
      foods: [
        { foodKey: 'tuna', portionMultiplier: 0.7 },
        { foodKey: 'whole_wheat_bread', portionMultiplier: 2 },
        { foodKey: 'spinach', portionMultiplier: 1 },
        { foodKey: 'olive_oil', portionMultiplier: 0.3 },
      ],
    },
  ],
}; 