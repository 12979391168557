import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Alert,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
  IconButton,
  Button,
  Tabs,
  Tab,
  Divider,
} from '@mui/material';
import { MacroSummary } from './MacroSummary';
import { CalorieCalculator } from './CalorieCalculator';
import { FOOD_ITEMS, MEAL_OPTIONS } from '../data/mealData';
import type { MealTime, MacroTargets, CalculationResult } from '../types';
import { calculateBMRAndMacros, calculateMealOption } from '../utils/calculations';
import { useAuth } from '../contexts/AuthContext';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import EggIcon from '@mui/icons-material/EggAlt';
import GrassIcon from '@mui/icons-material/Grass';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';

interface FoodWithPortions {
  foodKey: string;
  portionMultiplier: number;
}

function adjustPortionsForGoals(foods: FoodWithPortions[], goals: MacroTargets): FoodWithPortions[] {
  // Helper function to calculate protein ratio
  const getProteinRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.proteins * 4) / food.calories;

  // Helper function to calculate fat ratio
  const getFatRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.fats * 9) / food.calories;

  // Helper function to calculate carb ratio
  const getCarbRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.carbohydrates * 4) / food.calories;

  // Classify foods by their primary macro contribution
  const proteinFoods = foods.filter(food => {
    const foodItem = FOOD_ITEMS[food.foodKey];
    return foodItem && getProteinRatio(foodItem) > 0.3;
  }).sort((a, b) => {
    const aItem = FOOD_ITEMS[a.foodKey];
    const bItem = FOOD_ITEMS[b.foodKey];
    return getProteinRatio(bItem) - getProteinRatio(aItem);
  });

  const fatFoods = foods.filter(food => {
    const foodItem = FOOD_ITEMS[food.foodKey];
    return foodItem && getFatRatio(foodItem) > 0.3;
  }).sort((a, b) => {
    const aItem = FOOD_ITEMS[a.foodKey];
    const bItem = FOOD_ITEMS[b.foodKey];
    return getFatRatio(bItem) - getFatRatio(aItem);
  });

  const carbFoods = foods.filter(food => {
    const foodItem = FOOD_ITEMS[food.foodKey];
    return foodItem && getCarbRatio(foodItem) > 0.3;
  }).sort((a, b) => {
    const aItem = FOOD_ITEMS[a.foodKey];
    const bItem = FOOD_ITEMS[b.foodKey];
    return getCarbRatio(bItem) - getCarbRatio(aItem);
  });

  // Initialize all foods with minimum portions
  let adjustedFoods = foods.map(food => ({
    ...food,
    portionMultiplier: 0.5
  }));

  // First pass: Meet protein target with protein-rich foods
  let currentMacros = calculateMacros(adjustedFoods);
  const proteinNeeded = goals.proteins - currentMacros.proteins;

  if (proteinNeeded > 0 && proteinFoods.length > 0) {
    const totalProteinAvailable = proteinFoods.reduce((sum, food) => {
      const foodItem = FOOD_ITEMS[food.foodKey];
      return sum + (foodItem.proteins * 2); // Assuming max multiplier of 2.5
    }, 0);

    const proteinScale = Math.min(1, proteinNeeded / totalProteinAvailable);

    proteinFoods.forEach(food => {
      const foodItem = FOOD_ITEMS[food.foodKey];
      const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
      const baseMultiplier = 0.5 + (2 * proteinScale * getProteinRatio(foodItem));
      adjustedFoods[index].portionMultiplier = Math.min(2.5, baseMultiplier);
    });
  }

  // Second pass: Control fat content
  currentMacros = calculateMacros(adjustedFoods);
  const fatAdjustment = goals.fats - currentMacros.fats;

  if (fatFoods.length > 0) {
    if (fatAdjustment < 0) {
      // Reduce fat if we're over
      fatFoods.forEach(food => {
        const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
        adjustedFoods[index].portionMultiplier = Math.max(0.5, adjustedFoods[index].portionMultiplier * 0.8);
      });
    } else if (fatAdjustment > 0) {
      // Add minimal fat if needed
      const maxFatMultiplier = goals.carbohydrates <= 50 ? 7.0 : 1.5;
      fatFoods.forEach(food => {
        const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
        const currentMultiplier = adjustedFoods[index].portionMultiplier;
        adjustedFoods[index].portionMultiplier = Math.min(
          maxFatMultiplier,
          currentMultiplier * (1 + (fatAdjustment / goals.fats) * 0.5)
        );
      });
    }
  }

  // Final pass: Adjust carbs to hit calorie target
  currentMacros = calculateMacros(adjustedFoods);
  const calorieAdjustment = goals.calories - currentMacros.calories;

  if (calorieAdjustment > 0 && carbFoods.length > 0) {
    const totalCarbCalories = carbFoods.reduce((sum, food) => {
      const item = FOOD_ITEMS[food.foodKey];
      return sum + (item.calories * 5.5); // Assuming max multiplier of 6.0
    }, 0);

    const carbScale = Math.min(1, calorieAdjustment / totalCarbCalories);

    carbFoods.forEach(food => {
      const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
      const baseMultiplier = adjustedFoods[index].portionMultiplier;
      const newMultiplier = baseMultiplier + (5.5 * carbScale);
      adjustedFoods[index].portionMultiplier = Math.min(6.0, newMultiplier);
    });
  }

  // Final adjustment: Round and apply constraints
  return adjustedFoods.map(food => {
    const item = FOOD_ITEMS[food.foodKey];
    const isProtein = getProteinRatio(item) > 0.3;
    const isFat = getFatRatio(item) > 0.3;
    const isCarb = getCarbRatio(item) > 0.3;

    let maxMultiplier = 3.0;
    if (isProtein) maxMultiplier = 2.5;
    if (isFat) maxMultiplier = goals.carbohydrates <= 50 ? 7.0 : 1.5;
    if (isCarb) maxMultiplier = 6.0;

    return {
      ...food,
      portionMultiplier: Number(Math.min(maxMultiplier, Math.max(0.5, food.portionMultiplier)).toFixed(1))
    };
  });
}

function calculateMacros(foods: FoodWithPortions[]): MacroTargets {
  const macros = foods.reduce(
    (acc, { foodKey, portionMultiplier }) => {
      const food = FOOD_ITEMS[foodKey];
      if (!food) {
        console.error(`Food item not found: ${foodKey}`);
        return acc;
      }
      return {
        proteins: acc.proteins + food.proteins * portionMultiplier,
        carbohydrates: acc.carbohydrates + food.carbohydrates * portionMultiplier,
        fats: acc.fats + food.fats * portionMultiplier,
        calories: acc.calories + food.calories * portionMultiplier,
      };
    },
    { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 }
  );

  return {
    proteins: Number(macros.proteins.toFixed(1)),
    carbohydrates: Number(macros.carbohydrates.toFixed(1)),
    fats: Number(macros.fats.toFixed(1)),
    calories: Math.round(macros.calories)
  };
}

function MacroProgressBar({ value, max, color }: { value: number; max: number; color: string }) {
  const percentage = Math.min((value / max) * 100, 100);
  return (
    <Box sx={{ width: '100%', bgcolor: 'grey.100', borderRadius: 1, height: 4 }}>
      <Box
        sx={{
          width: `${percentage}%`,
          bgcolor: color,
          height: '100%',
          borderRadius: 1,
          transition: 'width 0.5s ease-in-out'
        }}
      />
    </Box>
  );
}

export const MealPlan: React.FC = () => {
  const { user } = useAuth();
  const [selectedMealTime, setSelectedMealTime] = useState<MealTime>('desayuno');
  const [selectedMeals, setSelectedMeals] = useState<Record<MealTime, CalculationResult | null>>({
    desayuno: null,
    almuerzo: null,
    comida: null,
    cena: null,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const [sortBy, setSortBy] = useState('default');
  const [showOptions, setShowOptions] = useState('all');
  const [dailyTargets, setDailyTargets] = useState<MacroTargets | null>(null);
  const [remainingMacros, setRemainingMacros] = useState<MacroTargets | null>(null);

  // Calculate total macros from all selected meals
  const totalMacros = useMemo(() => {
    const initialMacros = { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 };
    return Object.values(selectedMeals).reduce((acc, meal) => {
      if (!meal) return acc;
      return {
        proteins: acc.proteins + meal.macroTotals.proteins,
        carbohydrates: acc.carbohydrates + meal.macroTotals.carbohydrates,
        fats: acc.fats + meal.macroTotals.fats,
        calories: acc.calories + meal.macroTotals.calories,
      };
    }, initialMacros);
  }, [selectedMeals]);

  // Calculate daily targets when user settings change
  useEffect(() => {
    if (user?.settings) {
      const { macroTargets } = calculateBMRAndMacros(user.settings);
      setDailyTargets(macroTargets);
      updateRemainingMacros(macroTargets, selectedMeals);
    }
  }, [user?.settings]);

  // Scale all selected meals when macros target changes
  useEffect(() => {
    if (!dailyTargets || !Object.values(selectedMeals).some(meal => meal !== null)) return;

    // Get meal order
    const mealOrder: MealTime[] = ['desayuno', 'almuerzo', 'comida', 'cena'];
    
    // Recalculate meals in order
    const updatedMeals = { ...selectedMeals };
    let accumulatedMacros = { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 };

    mealOrder.forEach((mealTime) => {
      const meal = updatedMeals[mealTime];
      if (!meal) return;

      // Calculate remaining meals including current one
      const remainingMeals = mealOrder
        .slice(mealOrder.indexOf(mealTime))
        .filter(m => selectedMeals[m] !== null)
        .length;

      if (remainingMeals === 0) return;

      // Calculate targets for remaining meals
      const remainingTargets = {
        proteins: Math.max(0, (dailyTargets.proteins - accumulatedMacros.proteins) / remainingMeals),
        carbohydrates: Math.max(0, (dailyTargets.carbohydrates - accumulatedMacros.carbohydrates) / remainingMeals),
        fats: Math.max(0, (dailyTargets.fats - accumulatedMacros.fats) / remainingMeals),
        calories: Math.max(0, (dailyTargets.calories - accumulatedMacros.calories) / remainingMeals),
      };

      // Recalculate current meal
      updatedMeals[mealTime] = calculateMealOption(meal.mealTime, meal.selectedOption, remainingTargets);
      
      // Update accumulated macros
      accumulatedMacros = {
        proteins: accumulatedMacros.proteins + updatedMeals[mealTime]!.macroTotals.proteins,
        carbohydrates: accumulatedMacros.carbohydrates + updatedMeals[mealTime]!.macroTotals.carbohydrates,
        fats: accumulatedMacros.fats + updatedMeals[mealTime]!.macroTotals.fats,
        calories: accumulatedMacros.calories + updatedMeals[mealTime]!.macroTotals.calories,
      };
    });

    setSelectedMeals(updatedMeals);
    if (dailyTargets) {
      updateRemainingMacros(dailyTargets, updatedMeals);
    }
  }, [dailyTargets, selectedMeals]);

  // Update remaining macros whenever meals change
  const updateRemainingMacros = (targets: MacroTargets, meals: Record<MealTime, CalculationResult | null>) => {
    const consumed = Object.values(meals).reduce(
      (acc, meal) => {
        if (!meal) return acc;
        return {
          proteins: acc.proteins + meal.macroTotals.proteins,
          carbohydrates: acc.carbohydrates + meal.macroTotals.carbohydrates,
          fats: acc.fats + meal.macroTotals.fats,
          calories: acc.calories + meal.macroTotals.calories,
        };
      },
      { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 }
    );

    setRemainingMacros({
      proteins: targets.proteins - consumed.proteins,
      carbohydrates: targets.carbohydrates - consumed.carbohydrates,
      fats: targets.fats - consumed.fats,
      calories: targets.calories - consumed.calories,
    });
  };

  const handleMealSelect = (mealTime: MealTime, optionId: string) => {
    if (!dailyTargets) return;

    // Calculate remaining macros for this meal
    const mealOrder: MealTime[] = ['desayuno', 'almuerzo', 'comida', 'cena'];
    const currentMealIndex = mealOrder.indexOf(mealTime);
    
    // Calculate macros from meals before the current one
    const previousMealsMacros = mealOrder
      .slice(0, currentMealIndex)
      .reduce((acc, time) => {
        const meal = selectedMeals[time];
        if (!meal) return acc;
        return {
          proteins: acc.proteins + meal.macroTotals.proteins,
          carbohydrates: acc.carbohydrates + meal.macroTotals.carbohydrates,
          fats: acc.fats + meal.macroTotals.fats,
          calories: acc.calories + meal.macroTotals.calories,
        };
      }, { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 });

    // Calculate remaining meals including current one
    const remainingMealCount = mealOrder
      .slice(currentMealIndex)
      .filter(m => m === mealTime || selectedMeals[m] !== null)
      .length;

    // Calculate precise targets for this meal
    const mealTargets = {
      proteins: Number(((dailyTargets.proteins - previousMealsMacros.proteins) / remainingMealCount).toFixed(1)),
      carbohydrates: Number(((dailyTargets.carbohydrates - previousMealsMacros.carbohydrates) / remainingMealCount).toFixed(1)),
      fats: Number(((dailyTargets.fats - previousMealsMacros.fats) / remainingMealCount).toFixed(1)),
      calories: Math.round((dailyTargets.calories - previousMealsMacros.calories) / remainingMealCount)
    };

    // Get base meal option and adjust portions
    const baseOption = calculateMealOption(mealTime, optionId);
    const adjustedFoods = adjustPortionsForGoals(baseOption.foods, mealTargets);
    const adjustedMacros = calculateMacros(adjustedFoods);

    const mealOption: CalculationResult = {
      mealTime,
      selectedOption: optionId,
      macroTotals: adjustedMacros,
      portions: adjustedFoods.reduce((acc, { foodKey, portionMultiplier }) => {
        const food = FOOD_ITEMS[foodKey];
        if (!food) return acc;
        return {
          ...acc,
          [foodKey]: {
            amount: Number(portionMultiplier.toFixed(1)),
            unit: food.portionName,
            grams: Math.round(food.gramsPerPortion * portionMultiplier)
          }
        };
      }, {}),
      foods: adjustedFoods
    };

    setSelectedMeals((prev) => {
      const updated = {
        ...prev,
        [mealTime]: mealOption,
      };
      if (dailyTargets) {
        updateRemainingMacros(dailyTargets, updated);
      }
      return updated;
    });
  };

  const handleMealTimeChange = (_event: React.SyntheticEvent, newValue: MealTime) => {
    setSelectedMealTime(newValue);
  };

  const toggleFavorite = (optionId: string) => {
    const newFavorites = new Set(favorites);
    if (newFavorites.has(optionId)) {
      newFavorites.delete(optionId);
    } else {
      newFavorites.add(optionId);
    }
    setFavorites(newFavorites);
  };

  // Filter and sort meal options
  const filteredMealOptions = useMemo(() => {
    let options = MEAL_OPTIONS[selectedMealTime];
    
    // Filter by diet type
    if (user?.settings.dietType !== 'all') {
      const isKeto = user?.settings.dietType === 'keto';
      options = options.filter(option => {
        const isKetoMeal = option.id.includes('_keto');
        return isKeto ? isKetoMeal : !isKetoMeal;
      });
    }

    // Filter by favorites
    if (showOptions === 'favorites') {
      options = options.filter(option => favorites.has(option.id));
    }

    // Filter by search term
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      options = options.filter(option => {
        if (
          option.name.toLowerCase().includes(searchTermLower) ||
          option.description.toLowerCase().includes(searchTermLower)
        ) {
          return true;
        }

        return option.foods.some(food => {
          const foodItem = FOOD_ITEMS[food.foodKey];
          return foodItem?.name.toLowerCase().includes(searchTermLower);
        });
      });
    }

    // Sort options
    switch (sortBy) {
      case 'calories_asc':
        options.sort((a, b) => {
          const statsA = calculateMealOption(selectedMealTime, a.id).macroTotals;
          const statsB = calculateMealOption(selectedMealTime, b.id).macroTotals;
          return statsA.calories - statsB.calories;
        });
        break;
      case 'calories_desc':
        options.sort((a, b) => {
          const statsA = calculateMealOption(selectedMealTime, a.id).macroTotals;
          const statsB = calculateMealOption(selectedMealTime, b.id).macroTotals;
          return statsB.calories - statsA.calories;
        });
        break;
      case 'protein_desc':
        options.sort((a, b) => {
          const statsA = calculateMealOption(selectedMealTime, a.id).macroTotals;
          const statsB = calculateMealOption(selectedMealTime, b.id).macroTotals;
          return statsB.proteins - statsA.proteins;
        });
        break;
      case 'carbs_asc':
        options.sort((a, b) => {
          const statsA = calculateMealOption(selectedMealTime, a.id).macroTotals;
          const statsB = calculateMealOption(selectedMealTime, b.id).macroTotals;
          return statsA.carbohydrates - statsB.carbohydrates;
        });
        break;
    }

    return options;
  }, [selectedMealTime, searchTerm, user?.settings.dietType, sortBy, showOptions, favorites]);

  const mealTimeIcons = {
    desayuno: '🌅',
    almuerzo: '🍎',
    comida: '🍽️',
    cena: '🌙',
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CalorieCalculator />
        </Grid>
        <Grid item xs={12}>
          <MacroSummary />
        </Grid>

        {dailyTargets && remainingMacros && (
          <Grid item xs={12}>
            <Alert 
              severity={
                remainingMacros.calories < 0 || 
                remainingMacros.proteins < 0 || 
                remainingMacros.carbohydrates < 0 || 
                remainingMacros.fats < 0 ? "warning" : "info"
              }
              sx={{ mb: 2 }}
            >
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Typography
                  component="span"
                  sx={{ 
                    color: remainingMacros.calories < 0 ? 'error.main' : 'inherit',
                    fontWeight: remainingMacros.calories < 0 ? 'bold' : 'normal'
                  }}
                >
                  Calorías: {remainingMacros.calories > 0 ? '+' : ''}{Math.round(remainingMacros.calories)} kcal
                </Typography>
                <Typography
                  component="span"
                  sx={{ 
                    color: remainingMacros.proteins < 0 ? 'error.main' : 'inherit',
                    fontWeight: remainingMacros.proteins < 0 ? 'bold' : 'normal'
                  }}
                >
                  P: {remainingMacros.proteins > 0 ? '+' : ''}{Math.round(remainingMacros.proteins)}g
                </Typography>
                <Typography
                  component="span"
                  sx={{ 
                    color: remainingMacros.carbohydrates < 0 ? 'error.main' : 'inherit',
                    fontWeight: remainingMacros.carbohydrates < 0 ? 'bold' : 'normal'
                  }}
                >
                  C: {remainingMacros.carbohydrates > 0 ? '+' : ''}{Math.round(remainingMacros.carbohydrates)}g
                </Typography>
                <Typography
                  component="span"
                  sx={{ 
                    color: remainingMacros.fats < 0 ? 'error.main' : 'inherit',
                    fontWeight: remainingMacros.fats < 0 ? 'bold' : 'normal'
                  }}
                >
                  G: {remainingMacros.fats > 0 ? '+' : ''}{Math.round(remainingMacros.fats)}g
                </Typography>
              </Box>
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              placeholder="Buscar por nombre de comida o ingrediente..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                }
              }}
            />
          </Box>

          <Paper sx={{ p: 2, mb: 3, borderRadius: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Filtros
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Ordenar por</InputLabel>
                  <Select
                    value={sortBy}
                    label="Ordenar por"
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <MenuItem value="default">Por defecto</MenuItem>
                    <MenuItem value="calories_asc">Calorías ↑</MenuItem>
                    <MenuItem value="calories_desc">Calorías ↓</MenuItem>
                    <MenuItem value="protein_desc">Más proteína</MenuItem>
                    <MenuItem value="carbs_asc">Menos carbos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Mostrar</InputLabel>
                  <Select
                    value={showOptions}
                    label="Mostrar"
                    onChange={(e) => setShowOptions(e.target.value)}
                  >
                    <MenuItem value="all">Todas las opciones</MenuItem>
                    <MenuItem value="favorites">Solo favoritos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Box sx={{ 
            borderBottom: 1, 
            borderColor: 'divider', 
            mb: 4,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            p: 1
          }}>
            <Tabs
              value={selectedMealTime}
              onChange={handleMealTimeChange}
              aria-label="horarios de comida"
              variant="fullWidth"
              textColor="primary"
              indicatorColor="primary"
            >
              {Object.entries(mealTimeIcons).map(([time, icon]) => (
                <Tab 
                  key={time}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <span>{icon}</span>
                      <span style={{ textTransform: 'capitalize' }}>{time}</span>
                    </Box>
                  }
                  value={time}
                />
              ))}
            </Tabs>
          </Box>

          <Grid container spacing={3}>
            {filteredMealOptions.map((option) => {
              const mealStats = calculateMealOption(selectedMealTime, option.id).macroTotals;
              return (
                <Grid item xs={12} md={6} key={option.id}>
                  <Card sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                    }
                  }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                          {option.name}
                        </Typography>
                        <IconButton 
                          onClick={() => toggleFavorite(option.id)}
                          color={favorites.has(option.id) ? 'primary' : 'default'}
                        >
                          <FavoriteIcon />
                        </IconButton>
                      </Box>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {option.description}
                      </Typography>

                      <Box sx={{ mb: 2 }}>
                        <Chip
                          icon={<LocalFireDepartmentIcon />}
                          label={`${Math.round(mealStats.calories)} kcal`}
                          color="primary"
                          size="small"
                          sx={{ mr: 1, mb: 1 }}
                        />
                        <Chip
                          icon={<EggIcon />}
                          label={`${Math.round(mealStats.proteins)}g proteína`}
                          color="secondary"
                          size="small"
                          sx={{ mr: 1, mb: 1 }}
                        />
                      </Box>

                      <Typography variant="subtitle2" gutterBottom>
                        Ingredientes:
                      </Typography>
                      <Box sx={{ ml: 1 }}>
                        {option.foods.map((food) => {
                          const foodItem = FOOD_ITEMS[food.foodKey];
                          if (!foodItem) return null;
                          const grams = Math.round(foodItem.gramsPerPortion * food.portionMultiplier);
                          return (
                            <Typography 
                              key={food.foodKey} 
                              sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                fontSize: '0.875rem',
                                mb: 0.5 
                              }}
                            >
                              <span>{foodItem.name}</span>
                              <span style={{ color: 'text.secondary' }}>
                                {Number(food.portionMultiplier.toFixed(1))} {foodItem.portionName} ({grams}g)
                              </span>
                            </Typography>
                          );
                        })}
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => handleMealSelect(selectedMealTime, option.id)}
                        variant={selectedMeals[selectedMealTime]?.selectedOption === option.id ? 'contained' : 'outlined'}
                        fullWidth
                        sx={{ borderRadius: 2 }}
                      >
                        {selectedMeals[selectedMealTime]?.selectedOption === option.id ? 'Seleccionado' : 'Seleccionar'}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
            <Typography variant="h6" gutterBottom>
              Resumen de Comidas Seleccionadas
            </Typography>

            {Object.entries(selectedMeals).map(([mealTime, meal]) => meal && (
              <Box key={mealTime} sx={{ mb: 2, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {mealTimeIcons[mealTime as MealTime]}
                  <span style={{ textTransform: 'capitalize' }}>{mealTime}</span>
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {MEAL_OPTIONS[meal.mealTime].find(opt => opt.id === meal.selectedOption)?.name}
                </Typography>
                
                <Box sx={{ mb: 2 }}>
                  <Chip
                    icon={<LocalFireDepartmentIcon />}
                    label={`${Math.round(meal.macroTotals.calories)} kcal`}
                    size="small"
                    sx={{ mr: 0.5, mb: 0.5 }}
                  />
                  <Chip
                    icon={<EggIcon />}
                    label={`${Math.round(meal.macroTotals.proteins)}g prot`}
                    size="small"
                    sx={{ mr: 0.5, mb: 0.5 }}
                  />
                </Box>

                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                  Ingredientes:
                </Typography>
                <Box sx={{ ml: 1 }}>
                  {meal.foods.map(({ foodKey, portionMultiplier }) => {
                    const foodItem = FOOD_ITEMS[foodKey];
                    if (!foodItem) return null;
                    const grams = Math.round(foodItem.gramsPerPortion * portionMultiplier);
                    return (
                      <Typography 
                        key={foodKey} 
                        variant="body2" 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          fontSize: '0.875rem',
                          mb: 0.5 
                        }}
                      >
                        <span>{foodItem.name}</span>
                        <span style={{ color: 'text.secondary' }}>
                          {portionMultiplier.toFixed(1)} {foodItem.portionName} ({grams}g)
                        </span>
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              Totales Diarios
            </Typography>

            <Grid container spacing={2}>
              {[
                { label: 'Calorías', value: totalMacros.calories, icon: <LocalFireDepartmentIcon />, unit: 'kcal', target: dailyTargets?.calories },
                { label: 'Proteínas', value: totalMacros.proteins, icon: <EggIcon />, unit: 'g', target: dailyTargets?.proteins },
                { label: 'Carbohidratos', value: totalMacros.carbohydrates, icon: <BakeryDiningIcon />, unit: 'g', target: dailyTargets?.carbohydrates },
                { label: 'Grasas', value: totalMacros.fats, icon: <GrassIcon />, unit: 'g', target: dailyTargets?.fats }
              ].map(macro => (
                <Grid item xs={6} key={macro.label}>
                  <Paper sx={{ p: 1 }}>
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {macro.icon} {macro.label}
                    </Typography>
                    <Typography variant="h6">
                      {Math.round(macro.value)}{macro.unit}
                    </Typography>
                    {macro.target && (
                      <Box sx={{ mt: 1 }}>
                        <MacroProgressBar
                          value={macro.value}
                          max={macro.target}
                          color={
                            macro.label === 'Proteínas' ? '#ff4081' :
                            macro.label === 'Carbohidratos' ? '#2196f3' :
                            macro.label === 'Grasas' ? '#ffb74d' : '#4caf50'
                          }
                        />
                      </Box>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}; 