import React from 'react';
import { Container } from '@mui/material';
import { ProfileSettings } from '../components/profile/ProfileSettings';

export const ProfilePage: React.FC = () => {
  return (
    <Container>
      <ProfileSettings />
    </Container>
  );
}; 