import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import { LoginForm } from '../components/auth/LoginForm';
import { RegisterForm } from '../components/auth/RegisterForm';

export const AuthPage: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <Container maxWidth="sm">
      <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
        {isLogin ? (
          <LoginForm onRegisterClick={() => setIsLogin(false)} />
        ) : (
          <RegisterForm onLoginClick={() => setIsLogin(true)} />
        )}
      </Box>
    </Container>
  );
}; 