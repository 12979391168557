import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import type { UserSettings } from '../../types';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000/api';

export const ProfileSettings: React.FC = () => {
  const { user, updateUserSettings } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [settings, setSettings] = useState<UserSettings>({
    gender: 'male',
    weight: 70,
    height: 170,
    age: 30,
    activityLevel: 'moderate',
    goal: 'maintenance',
    dietType: 'all',
    calorieGoalAdjustment: 500
  });

  useEffect(() => {
    if (user?.settings) {
      setSettings(user.settings);
    }
  }, [user]);

  const handleChange = (field: keyof UserSettings, value: any) => {
    setSettings(prev => {
      // Ensure numeric values are stored as numbers
      if (field === 'weight' || field === 'height' || field === 'age' || field === 'calorieGoalAdjustment') {
        value = Number(value);
      }
      return {
        ...prev,
        [field]: value
      };
    });
  };

  const logWeight = async (weight: number) => {
    try {
      const token = localStorage.getItem('authToken');
      await fetch(`${API_URL}/weight`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ weight }),
      });
    } catch (error) {
      console.error('Error logging weight:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setLoading(true);

    // Validate all required fields are present and have valid values
    if (!settings.gender || !settings.weight || !settings.height || !settings.age || 
        !settings.activityLevel || !settings.goal || !settings.dietType || !settings.calorieGoalAdjustment) {
      setError('Todos los campos son obligatorios');
      setLoading(false);
      return;
    }

    // Only include the fields that the backend expects
    const settingsToSend = {
      gender: settings.gender,
      weight: Number(settings.weight),
      height: Number(settings.height),
      age: Number(settings.age),
      activityLevel: settings.activityLevel,
      goal: settings.goal,
      dietType: settings.dietType,
      calorieGoalAdjustment: Number(settings.calorieGoalAdjustment),
      ...(settings.manualCaloriesBurned !== undefined && {
        manualCaloriesBurned: Number(settings.manualCaloriesBurned)
      })
    };

    try {
      // If weight has changed, log it
      if (user?.settings && settings.weight !== user.settings.weight) {
        await logWeight(settings.weight);
      }

      await updateUserSettings(settingsToSend);
      setSuccess(true);

      // Dispatch a custom event to notify the dashboard
      window.dispatchEvent(new CustomEvent('weightUpdated'));
    } catch (err: any) {
      console.error('Error updating settings:', err.response?.data || err);
      if (err.response?.data?.errors) {
        const errorMessages = err.response.data.errors
          .map((error: any) => `${error.field}: ${error.message}`)
          .join(', ');
        setError(errorMessages);
      } else {
        setError(err.message || 'Error al actualizar la configuración');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 800, mx: 'auto', my: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Configuración del Perfil
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography color="error" variant="caption">
          * Required
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Configuración actualizada correctamente
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!settings.gender}>
              <InputLabel>Género</InputLabel>
              <Select
                value={settings.gender}
                label="Género *"
                onChange={(e) => handleChange('gender', e.target.value)}
                disabled={loading}
              >
                <MenuItem value="male">Hombre</MenuItem>
                <MenuItem value="female">Mujer</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Edad"
              type="number"
              value={settings.age}
              onChange={(e) => handleChange('age', Number(e.target.value))}
              disabled={loading}
              inputProps={{ min: 1, max: 120 }}
              error={settings.age < 1 || settings.age > 120}
              helperText={settings.age < 1 || settings.age > 120 ? "La edad debe estar entre 1 y 120 años" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Peso (kg)"
              type="number"
              value={settings.weight}
              onChange={(e) => handleChange('weight', Number(e.target.value))}
              disabled={loading}
              inputProps={{ min: 30, max: 300, step: 0.1 }}
              error={settings.weight < 30 || settings.weight > 300}
              helperText={settings.weight < 30 || settings.weight > 300 ? "El peso debe estar entre 30 y 300 kg" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Altura (cm)"
              type="number"
              value={settings.height}
              onChange={(e) => handleChange('height', Number(e.target.value))}
              disabled={loading}
              inputProps={{ min: 100, max: 250 }}
              error={settings.height < 100 || settings.height > 250}
              helperText={settings.height < 100 || settings.height > 250 ? "La altura debe estar entre 100 y 250 cm" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!settings.activityLevel}>
              <InputLabel>Nivel de Actividad</InputLabel>
              <Select
                value={settings.activityLevel}
                label="Nivel de Actividad *"
                onChange={(e) => handleChange('activityLevel', e.target.value)}
                disabled={loading}
              >
                <MenuItem value="sedentary">Sedentario</MenuItem>
                <MenuItem value="light">Actividad Ligera</MenuItem>
                <MenuItem value="moderate">Actividad Moderada</MenuItem>
                <MenuItem value="active">Actividad Alta</MenuItem>
                <MenuItem value="very_active">Actividad Muy Alta</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!settings.goal}>
              <InputLabel>Objetivo</InputLabel>
              <Select
                value={settings.goal}
                label="Objetivo *"
                onChange={(e) => handleChange('goal', e.target.value)}
                disabled={loading}
              >
                <MenuItem value="maintenance">Mantenimiento</MenuItem>
                <MenuItem value="deficit">Pérdida de Peso</MenuItem>
                <MenuItem value="surplus">Ganancia de Peso</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!settings.dietType}>
              <InputLabel>Tipo de Dieta</InputLabel>
              <Select
                value={settings.dietType}
                label="Tipo de Dieta *"
                onChange={(e) => handleChange('dietType', e.target.value)}
                disabled={loading}
              >
                <MenuItem value="all">Normal</MenuItem>
                <MenuItem value="low_fat">Baja en Grasas</MenuItem>
                <MenuItem value="keto">Cetogénica</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Ajuste de Calorías"
              type="number"
              value={settings.calorieGoalAdjustment}
              onChange={(e) => handleChange('calorieGoalAdjustment', Number(e.target.value))}
              disabled={loading}
              inputProps={{ min: 0, max: 1000, step: 50 }}
              error={settings.calorieGoalAdjustment < 0 || settings.calorieGoalAdjustment > 1000}
              helperText={
                settings.calorieGoalAdjustment < 0 || settings.calorieGoalAdjustment > 1000 
                  ? "El ajuste debe estar entre 0 y 1000 calorías" 
                  : "Calorías a añadir/restar según tu objetivo"
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar Cambios'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}; 