import React from 'react';
import { Container } from '@mui/material';
import { MealPlan } from './MealPlan';

export const MainApp: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <MealPlan />
    </Container>
  );
}; 