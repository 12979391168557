import type { UserSettings, BMRCalculation, MacroTargets, CalculationResult, MealTime } from '../types';
import { MEAL_OPTIONS, FOOD_ITEMS } from '../data/mealData';

// Activity level multipliers
export const ACTIVITY_MULTIPLIERS = {
  sedentary: 1.2,
  light: 1.375,
  moderate: 1.55,
  active: 1.725,
  very_active: 1.9,
};

const CALORIES_PER_GRAM = {
  protein: 4,
  carb: 4,
  fat: 9,
};

export function calculateBMRAndMacros(settings: UserSettings): BMRCalculation {
  // Calculate BMR using Mifflin-St Jeor Equation
  const bmr = settings.gender === 'male'
    ? (10 * settings.weight) + (6.25 * settings.height) - (5 * settings.age) + 5
    : (10 * settings.weight) + (6.25 * settings.height) - (5 * settings.age) - 161;

  // Calculate TDEE (Total Daily Energy Expenditure)
  const tdee = settings.manualCaloriesBurned 
    ? bmr + settings.manualCaloriesBurned
    : bmr * ACTIVITY_MULTIPLIERS[settings.activityLevel];

  // Calculate target calories based on goal and adjustment
  let targetCalories = tdee;
  if (settings.goal === 'deficit') {
    targetCalories -= settings.calorieGoalAdjustment;
  } else if (settings.goal === 'surplus') {
    targetCalories += settings.calorieGoalAdjustment;
  }
  targetCalories = Math.max(1200, targetCalories); // Minimum healthy calories

  // Calculate protein the same for both diets
  const proteinGrams = Math.round(settings.weight * 2.2); // 2.2g per kg of body weight
  const proteinCalories = proteinGrams * CALORIES_PER_GRAM.protein;

  if (settings.dietType === 'keto') {
    // For keto: max 50g carbs, rest of calories from fat
    const carbGrams = 50; // Maximum carbs for keto
    const carbCalories = carbGrams * CALORIES_PER_GRAM.carb;
    const remainingCalories = targetCalories - (proteinCalories + carbCalories);
    const fatGrams = Math.max(Math.round(remainingCalories / CALORIES_PER_GRAM.fat), Math.round(settings.weight * 1.5)); // Minimum 1.5g/kg of body weight

    return {
      bmr: Math.round(bmr),
      tdee: Math.round(tdee),
      targetCalories: Math.round(targetCalories),
      macroTargets: {
        proteins: proteinGrams,
        fats: fatGrams,
        carbohydrates: carbGrams,
        calories: Math.round(targetCalories)
      }
    };
  } else {
    // For low fat diet: calculate as before
    const fatGrams = Math.round(settings.weight * 1); // 1g per kg of body weight
    const fatCalories = fatGrams * CALORIES_PER_GRAM.fat;
    const carbCalories = targetCalories - (proteinCalories + fatCalories);
    const carbGrams = Math.max(0, Math.round(carbCalories / CALORIES_PER_GRAM.carb));

    return {
      bmr: Math.round(bmr),
      tdee: Math.round(tdee),
      targetCalories: Math.round(targetCalories),
      macroTargets: {
        proteins: proteinGrams,
        fats: fatGrams,
        carbohydrates: carbGrams,
        calories: Math.round(targetCalories)
      }
    };
  }
}

export function calculateMacros(foods: { foodKey: string; portionMultiplier: number }[]): MacroTargets {
  const macros = foods.reduce(
    (acc, { foodKey, portionMultiplier }) => {
      const food = FOOD_ITEMS[foodKey];
      if (!food) {
        console.error(`Food item not found: ${foodKey}`);
        return acc;
      }
      return {
        proteins: acc.proteins + food.proteins * portionMultiplier,
        carbohydrates: acc.carbohydrates + food.carbohydrates * portionMultiplier,
        fats: acc.fats + food.fats * portionMultiplier,
        calories: acc.calories + food.calories * portionMultiplier,
      };
    },
    { proteins: 0, carbohydrates: 0, fats: 0, calories: 0 }
  );

  return {
    proteins: Math.round(macros.proteins),
    carbohydrates: Math.round(macros.carbohydrates),
    fats: Math.round(macros.fats),
    calories: Math.round(macros.calories)
  };
}

export function calculateMealOption(
  mealTime: MealTime,
  optionId: string,
  goals?: MacroTargets
): CalculationResult {
  const selectedOption = MEAL_OPTIONS[mealTime].find(
    (option) => option.id === optionId
  );

  if (!selectedOption) {
    throw new Error(`Meal option ${optionId} not found for ${mealTime}`);
  }

  let foods = selectedOption.foods;
  const macroTotals = calculateMacros(foods);

  // If goals are provided, adjust portions to match the goals
  if (goals) {
    foods = adjustPortionsForGoals(foods, goals);
  }

  // Calculate portions for display
  const portions = foods.reduce((acc, { foodKey, portionMultiplier }) => {
    const food = FOOD_ITEMS[foodKey];
    if (!food) return acc;
    return {
      ...acc,
      [foodKey]: {
        amount: Number(portionMultiplier.toFixed(1)),
        unit: food.portionName,
        grams: Math.round(food.gramsPerPortion * portionMultiplier),
      },
    };
  }, {});

  return {
    mealTime,
    selectedOption: optionId,
    portions,
    macroTotals: goals ? calculateMacros(foods) : macroTotals,
    foods
  };
}

export function adjustPortionsForGoals(
  foods: { foodKey: string; portionMultiplier: number }[],
  goals: MacroTargets
) {
  // Helper functions to calculate macro ratios
  const getProteinRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.proteins * CALORIES_PER_GRAM.protein) / food.calories;
  
  const getFatRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.fats * CALORIES_PER_GRAM.fat) / food.calories;
  
  const getCarbRatio = (food: typeof FOOD_ITEMS[keyof typeof FOOD_ITEMS]) => 
    (food.carbohydrates * CALORIES_PER_GRAM.carb) / food.calories;

  // Classify foods by their primary macro contribution
  const proteinFoods = foods.filter(food => {
    const item = FOOD_ITEMS[food.foodKey];
    return getProteinRatio(item) > 0.25;
  });

  const fatFoods = foods.filter(food => {
    const item = FOOD_ITEMS[food.foodKey];
    return getFatRatio(item) > 0.35;
  });

  const carbFoods = foods.filter(food => {
    const item = FOOD_ITEMS[food.foodKey];
    return getCarbRatio(item) > 0.3; // Lowered threshold further for carbs
  });

  // Initialize with zero portions
  let adjustedFoods = foods.map(food => ({
    ...food,
    portionMultiplier: 0.0
  }));

  let currentTotals = calculateMacros(adjustedFoods);
  
  // First pass: Meet protein target with protein-rich foods
  let currentMacros = calculateMacros(adjustedFoods);
  const proteinNeeded = goals.proteins - currentMacros.proteins;

  if (proteinNeeded > 0 && proteinFoods.length > 0) {
    const totalProteinAvailable = proteinFoods.reduce((sum, food) => {
      const foodItem = FOOD_ITEMS[food.foodKey];
      return sum + (foodItem.proteins * 2); // Assuming max multiplier of 2.5
    }, 0);

    const proteinScale = Math.min(1, proteinNeeded / totalProteinAvailable);

    proteinFoods.forEach(food => {
      const foodItem = FOOD_ITEMS[food.foodKey];
      const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
      const baseMultiplier = 0.5 + (2 * proteinScale * getProteinRatio(foodItem));
      adjustedFoods[index].portionMultiplier = Math.min(2.5, baseMultiplier);
    });
  }

  // Second pass: Control fat content
  currentMacros = calculateMacros(adjustedFoods);
  const fatAdjustment = goals.fats - currentMacros.fats;

  if (fatFoods.length > 0) {
    if (fatAdjustment < 0) {
      // Reduce fat if we're over
      fatFoods.forEach(food => {
        const foodItem = FOOD_ITEMS[food.foodKey];
        const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
        adjustedFoods[index].portionMultiplier = Math.max(0.5, adjustedFoods[index].portionMultiplier * (1 - getFatRatio(foodItem) * 0.2));
      });
    } else if (fatAdjustment > 0) {
      // Add minimal fat if needed
      const maxFatMultiplier = goals.carbohydrates <= 50 ? 7.0 : 1.5;
      fatFoods.forEach(food => {
        const foodItem = FOOD_ITEMS[food.foodKey];
        const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
        const currentMultiplier = adjustedFoods[index].portionMultiplier;
        adjustedFoods[index].portionMultiplier = Math.min(
          maxFatMultiplier,
          currentMultiplier * (1 + (fatAdjustment / goals.fats) * getFatRatio(foodItem))
        );
      });
    }
  }

  // Final pass: Very aggressively adjust carbs
  if (carbFoods.length > 0) {
    // Sort carb foods by carb content per calorie
    const sortedCarbFoods = [...carbFoods].sort((a, b) => {
      const aItem = FOOD_ITEMS[a.foodKey];
      const bItem = FOOD_ITEMS[b.foodKey];
      return getCarbRatio(bItem) - getCarbRatio(aItem);
    });

    // First try: Very aggressive with primary carb sources
    sortedCarbFoods.slice(0, 2).forEach(food => {
      const foodItem = FOOD_ITEMS[food.foodKey];
      const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
      let currentPortion = adjustedFoods[index].portionMultiplier;
      
      // Much more aggressive carb increases based on carb ratio
      while (currentPortion < 15.0 * getCarbRatio(foodItem) && 
             (currentTotals.calories < goals.calories || 
              currentTotals.carbohydrates < goals.carbohydrates)) {
        currentPortion += 1.0 * getCarbRatio(foodItem); // Scale increment by carb ratio
        adjustedFoods[index].portionMultiplier = currentPortion;
        currentTotals = calculateMacros(adjustedFoods);
      }
    });

    // If still under target, try remaining carb foods
    if (currentTotals.calories < goals.calories * 0.95 || 
        currentTotals.carbohydrates < goals.carbohydrates) {
      sortedCarbFoods.slice(2).forEach(food => {
        const foodItem = FOOD_ITEMS[food.foodKey];
        const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
        let currentPortion = adjustedFoods[index].portionMultiplier;
        
        while (currentPortion < 12.0 * getCarbRatio(foodItem) && 
               (currentTotals.calories < goals.calories || 
                currentTotals.carbohydrates < goals.carbohydrates)) {
          currentPortion += 0.8 * getCarbRatio(foodItem); // Scale increment by carb ratio
          adjustedFoods[index].portionMultiplier = currentPortion;
          currentTotals = calculateMacros(adjustedFoods);
        }
      });
    }

    // Final attempt with any remaining foods that have some carbs
    if (currentTotals.calories < goals.calories * 0.95) {
      foods.forEach(food => {
        const foodItem = FOOD_ITEMS[food.foodKey];
        if (foodItem.carbohydrates > 0) {
          const index = adjustedFoods.findIndex(f => f.foodKey === food.foodKey);
          let currentPortion = adjustedFoods[index].portionMultiplier;
          
          while (currentPortion < 10.0 && currentTotals.calories < goals.calories) {
            currentPortion += 0.5;
            adjustedFoods[index].portionMultiplier = currentPortion;
            currentTotals = calculateMacros(adjustedFoods);
          }
        }
      });
    }
  }

  // Round to 1 decimal and apply final constraints
  return adjustedFoods.map(food => {
    const item = FOOD_ITEMS[food.foodKey];
    const isProtein = getProteinRatio(item) > 0.25;
    const isFat = getFatRatio(item) > 0.35;
    const isCarb = getCarbRatio(item) > 0.3;

    let maxMultiplier = 6.0;
    if (isProtein) maxMultiplier = 3.0;
    if (isFat) maxMultiplier = 2.0;
    if (isCarb) maxMultiplier = 15.0; // Much higher limit for carbs

    return {
      ...food,
      portionMultiplier: Number(Math.min(maxMultiplier, Math.max(0.0, food.portionMultiplier)).toFixed(1))
    };
  });
} 