import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  styled,
} from '@mui/material';
import {
  LocalFireDepartment as LocalFireDepartmentIcon,
  Egg as EggIcon,
  BakeryDining as BakeryDiningIcon,
  Grass as GrassIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { calculateBMRAndMacros } from '../utils/calculations';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 4,
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
  },
}));

const MacroProgressBar: React.FC<{ value: number; max: number; color: string }> = ({ value, max, color }) => {
  const progress = Math.min((value / max) * 100, 100);
  return (
    <StyledLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        backgroundColor: `${color}22`,
        '& .MuiLinearProgress-bar': {
          backgroundColor: color,
        },
      }}
    />
  );
};

export const MacroSummary: React.FC = () => {
  const { user } = useAuth();
  const bmrCalculation = user ? calculateBMRAndMacros(user.settings) : null;

  const macros = [
    {
      label: 'Calorías',
      value: bmrCalculation?.targetCalories || 0,
      target: bmrCalculation?.targetCalories || 0,
      icon: <LocalFireDepartmentIcon />,
      unit: 'kcal',
      color: '#4caf50',
    },
    {
      label: 'Proteínas',
      value: bmrCalculation?.macroTargets.proteins || 0,
      target: bmrCalculation?.macroTargets.proteins || 0,
      icon: <EggIcon />,
      unit: 'g',
      color: '#ff4081',
    },
    {
      label: 'Carbohidratos',
      value: bmrCalculation?.macroTargets.carbohydrates || 0,
      target: bmrCalculation?.macroTargets.carbohydrates || 0,
      icon: <BakeryDiningIcon />,
      unit: 'g',
      color: '#2196f3',
    },
    {
      label: 'Grasas',
      value: bmrCalculation?.macroTargets.fats || 0,
      target: bmrCalculation?.macroTargets.fats || 0,
      icon: <GrassIcon />,
      unit: 'g',
      color: '#ffb74d',
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {macros.map((macro) => (
          <Grid item xs={12} sm={6} md={3} key={macro.label}>
            <Paper sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                {macro.icon}
                <Typography variant="subtitle2">{macro.label}</Typography>
              </Box>
              <Typography variant="h6" gutterBottom>
                {Math.round(macro.value)}{macro.unit}
              </Typography>
              <MacroProgressBar
                value={macro.value}
                max={macro.target}
                color={macro.color}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}; 