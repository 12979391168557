import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  RemoveCircleOutline as RemoveIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

interface WeightLog {
  _id: string;
  weight: number;
  date: string;
  goal: 'maintenance' | 'deficit' | 'surplus';
  progress: 'positive' | 'negative' | 'initial';
  weightDiff?: number;
}

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000/api';

export const WeightDashboard: React.FC = () => {
  const [weightLogs, setWeightLogs] = useState<WeightLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchWeightLogs = async () => {
    try {
      setError(null);
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${API_URL}/weight`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch weight logs');

      const data = await response.json();
      setWeightLogs(data);
    } catch (error) {
      console.error('Error fetching weight logs:', error);
      setError('Error al cargar el historial de peso');
    } finally {
      setLoading(false);
    }
  };

  // Fetch on mount and when user changes
  useEffect(() => {
    fetchWeightLogs();
  }, []);

  // Set up polling to refresh data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchWeightLogs();
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, []);

  // Listen for weight updates
  useEffect(() => {
    const handleWeightUpdate = () => {
      fetchWeightLogs();
    };

    window.addEventListener('weightUpdated', handleWeightUpdate);
    return () => window.removeEventListener('weightUpdated', handleWeightUpdate);
  }, []);

  const chartData = {
    labels: weightLogs.map(log => format(new Date(log.date), 'dd MMM', { locale: es })),
    datasets: [
      {
        label: 'Peso (kg)',
        data: weightLogs.map(log => log.weight),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Evolución del Peso',
      },
    },
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  if (loading && weightLogs.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button onClick={fetchWeightLogs} variant="contained">
          Reintentar
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Historial de Peso
              </Typography>
              {loading && (
                <CircularProgress size={20} sx={{ ml: 2 }} />
              )}
            </Box>
            <Box sx={{ height: 300, mb: 4 }}>
              <Line data={chartData} options={chartOptions} />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell align="right">Peso</TableCell>
                  <TableCell align="right">Diferencia</TableCell>
                  <TableCell align="center">Progreso</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {weightLogs.map((log) => (
                  <TableRow key={log._id}>
                    <TableCell>
                      {format(new Date(log.date), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell align="right">{log.weight} kg</TableCell>
                    <TableCell align="right">
                      {log.weightDiff !== undefined && (
                        <Typography
                          component="span"
                          color={log.progress === 'positive' ? 'success.main' : 'error.main'}
                        >
                          {log.weightDiff > 0 ? '+' : ''}
                          {log.weightDiff.toFixed(1)} kg
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {log.progress === 'positive' ? (
                        <Tooltip title="Progreso positivo">
                          <IconButton size="small" color="success">
                            {log.goal === 'deficit' ? <TrendingDownIcon /> : <TrendingUpIcon />}
                          </IconButton>
                        </Tooltip>
                      ) : log.progress === 'negative' ? (
                        <Tooltip title="Progreso negativo">
                          <IconButton size="small" color="error">
                            {log.goal === 'deficit' ? <TrendingUpIcon /> : <TrendingDownIcon />}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Primer registro">
                          <RemoveIcon color="disabled" />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}; 