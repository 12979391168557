import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  Info as InfoIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  DirectionsRun as DirectionsRunIcon,
  Flag as FlagIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { calculateBMRAndMacros } from '../utils/calculations';

const CalorieInfoItem: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: number;
  tooltip: string;
}> = ({ icon, label, value, tooltip }) => (
  <Grid item xs={12} sm={4}>
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        {icon}
        <Typography variant="subtitle2">
          {label}
        </Typography>
        <Tooltip title={tooltip} arrow>
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="h5">
        {Math.round(value)} kcal
      </Typography>
    </Paper>
  </Grid>
);

export const CalorieCalculator: React.FC = () => {
  const { user, updateUserSettings } = useAuth();
  const [manualCalories, setManualCalories] = useState<string>('');
  const [bmr, setBmr] = useState(0);
  const [tdee, setTdee] = useState(0);
  const [targetCalories, setTargetCalories] = useState(0);

  useEffect(() => {
    if (user) {
      const calculation = calculateBMRAndMacros(user.settings);
      setBmr(calculation.bmr);
      setTdee(calculation.tdee);
      setTargetCalories(calculation.targetCalories);
    }
  }, [user]);

  const handleManualCaloriesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setManualCalories(value);
    
    if (user) {
      if (value && !isNaN(Number(value))) {
        const manualValue = Number(value);
        // Create temporary settings with manual calories
        const tempSettings = {
          ...user.settings,
          manualCaloriesBurned: manualValue
        };
        
        // Calculate new values with manual calories
        const calculation = calculateBMRAndMacros(tempSettings);
        setBmr(calculation.bmr);
        setTdee(calculation.tdee);
        setTargetCalories(calculation.targetCalories);
        
        // Update user settings to reflect the manual calories
        try {
          await updateUserSettings(tempSettings);
        } catch (error) {
          console.error('Error updating settings:', error);
        }
      } else {
        // Reset to original settings without manual calories
        const tempSettings = {
          ...user.settings,
          manualCaloriesBurned: undefined
        };
        
        const calculation = calculateBMRAndMacros(tempSettings);
        setBmr(calculation.bmr);
        setTdee(calculation.tdee);
        setTargetCalories(calculation.targetCalories);
        
        // Update user settings to remove manual calories
        try {
          await updateUserSettings(tempSettings);
        } catch (error) {
          console.error('Error updating settings:', error);
        }
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <CalorieInfoItem
          icon={<LocalFireDepartmentIcon color="primary" />}
          label="BMR"
          value={bmr}
          tooltip="Tasa Metabólica Basal - Calorías que quemas en reposo absoluto"
        />
        <CalorieInfoItem
          icon={<DirectionsRunIcon color="secondary" />}
          label="TDEE"
          value={tdee}
          tooltip="Gasto Total Diario - BMR ajustado por tu nivel de actividad"
        />
        <CalorieInfoItem
          icon={<FlagIcon color="success" />}
          label="Objetivo"
          value={targetCalories}
          tooltip="Calorías objetivo según tu meta (mantenimiento, déficit o superávit)"
        />
      </Grid>

      <Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Ajuste Manual de Calorías"
          type="number"
          value={manualCalories}
          onChange={handleManualCaloriesChange}
          placeholder="Introduce tus calorías objetivo manualmente"
          helperText="Deja en blanco para usar el cálculo automático según tu actividad y objetivo"
          InputProps={{
            endAdornment: <Typography sx={{ ml: 1 }}>kcal</Typography>,
          }}
        />
      </Box>
    </Box>
  );
}; 