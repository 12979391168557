import React from 'react';
import { Container } from '@mui/material';
import { WeightDashboard } from '../components/WeightDashboard';

export const DashboardPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <WeightDashboard />
    </Container>
  );
}; 